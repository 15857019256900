@import './vars';


@media screen and (max-width: 2560px) {

  //span.nin_ja_shareblock{
  //  left:47%
  //}
}

@media screen and (max-width: 1440px) {

}


@media screen and (max-width: 1024px) {
  .header-nin_header_logo_1{
    display: flex;
  }
  .header-nin_header_logo_2{
    display: none;
  }
  .ninja__blueSidebar ul{
    margin-left: 40px;
  }
  .nin_ja_music__title{
    justify-content: flex-start;
    margin-left: 30px;
  }
  .header-nin_header_menu{
    align-items: flex-start;
    margin-left: 20px;
  }
  .header-nin_header_menu a{
    width: 50%;
  }
  .header-nin_header{
    background: var(--clr-2);
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .ninja__blueSite {
    padding: 40px 15px;
  }
  .ninja__blueWrapper {
    width: 100%;
  }
  .header-nin_header_logo{
    margin-bottom: 0;
  }
  .ninja__blueSidebar {
    display: none;
    flex-direction: column;
    width: 420px;
    padding: 15px;
    position: absolute;
    background: var(--clr-2);
    z-index: 2000;
    right: 0;
    top: 0;
    left: 0;
    /* padding-top: 112px; */
    margin-top: 93px;
  }
  .header-nin_header_menu {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .header-nin_mobile__button {
    display: flex;
    justify-self: end;
  }
  .nin_ja_track-info, .nin_ja_track-central {
    height: 70px;
  }
  .nin_ja_mute-box__muteUnmute {
    display: none;
  }
  .header-nin_header_menu a {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .nin_ja_mute-box {
    display: none;
  }
  //.nin_ja_letter__link{
  //    background: var(--clr-3);
  //}

}

@media screen and (max-width: 992px) {
  .nin_ja_melodious__list a {
    font-size: 24px;
  }
  .nin_ja_track-info {
    margin-right: 60px;
  }
}

@media screen and (max-width: 920px) {
}

@media screen and (max-width: 768px) {
  .header-nin_header{
    margin-bottom: 20px;
  }
  .header-nin_mobile__button-line{
    height: 3px;
    margin-bottom: 3px;
    border-top: 3px;
  }
  .ninja__bluePopular_title {
    font-size: 25px;
  }
  .nin_ja_player-title_heading {
    margin-right: 0;
  }
  .nin_ja_track__leftWrapper {
    justify-self: end;
  }
  //.track {
  //  display: grid;
  //  grid-template-columns: 1fr 1fr;
  //}
  .nin_ja_player-title {
    justify-content: center;
    margin-top: 15px;
    order: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .nin_ja_track-central {
    margin-right: 0;
  }
  .nin_ja_results-info__card {
    padding: 15px 10px;
  }
  .nin_ja_melodious__list {
    flex-direction: row;
  }
  .nin_ja_melodious__list a {
    margin-right: 5px;
    margin-left: 5px;
  }
  .nin_ja_boxline__bottom {
    text-align: center;
  }
  .nin_ja_mute-box {
    margin-right: 30px;
  }
  .nin_ja_about {
    flex-direction: column;
  }
  .nin_ja_logo-link {
    display: none;
  }
  .nin_ja_wrapper {
    margin-left: 0;
  }

  .nin_ja_mobile-nav__button {
    width: min-content;
    justify-self: end;
  }
  .nin_ja_song-aplhabet {
    width: auto;
  }
  .nin_ja_popular_title {
    font-size: 25px;
  }
  .nin_ja_popular_logo {
    width: 40px;
    height: 40px;
  }
  .nin_ja_copyright_box-mail {
    font-size: 24px;
  }
  .nin_ja_sidebar {
    display: none;
  }
  .nin_ja_wrapper {
    width: 100%;
  }
  .nin_ja_melodious__list a::before {
    display: none;
  }
  .nin_ja_footer_copy {
    width: 100%;
  }
  .nin_ja_copyright_box-mail {
    font-size: 20px;
  }
  span.nin_ja_shareblock h1 {
    font-size: 20px;
  }
  span.nin_ja_shareblock a {
    font-size: 20px;
  }
  .header-nin_header_menu a {
    margin-left: 3px;
  }


}

@media screen and (max-width: 624px) {
  .header-nin_header_menu a{
    width: 100%;
  }
  .header-nin_header_menu{
    margin-left: 0;
  }
  .ninja__blueSidebar{
    box-sizing: border-box;
    width: 100%;
  }
  .nin_ja_copyright_box-mail a {
    margin-left: 0;
  }
  .track {
    display: flex;
    //flex-direction: column;
  }
  .nin_ja_copyright_box {
    margin-bottom: 230px;
  }
  .social-box {
    right: 30px;
  }

  .nin_ja_about {
    margin-bottom: 190px;
  }
  .nin_ja_track-central {
    margin-right: 0;
  }
  .nin_ja_track-info {
    margin-bottom: 10px;
  }
  .nin_ja_mute-box {
    width: auto;
    justify-self: center;
    grid-column-start: 2;
    margin-right: 0;
  }
  .nin_ja_time {
    margin-right: 15px;
  }
  .nin_ja_zero-time {
    margin-left: 0;
    font-size: 16px;
  }
  .nin_ja_time_space {
    font-size: 16px;
  }
  .nin_ja_timeBox {
    margin-right: 0;
    font-size: 16px;
  }
  .nin_ja_copyright_box-mail {
    flex-direction: column;
  }
  span.nin_ja_shareblock{
    left:16%;
  }
}

@media screen and (max-width: 670px) {
  .nin_ja_melodious__list a {
    justify-content: center;
  }
  //span.nin_ja_shareblock{
  //  width: 96%;
  //  left: 0%;
  //}
}

@media screen and (max-width: 577px) {

  .nin_ja_popular_logo {
    display: none;
  }
  .nin_ja_popular_title {
    text-align: center;
  }
  .nin_ja_sotial-network-vk_btn {
    width: 100%;
    margin-bottom: 20px;
  }
  .nin_ja_sotial-network-vk_link {
    width: 100%;
  }
  .nin_ja_sotial-network-vk_input {
    flex-direction: column;
  }


  .nin_ja_download_vk {
    width: 100%;
  }
  .nin_ja_vk {
    width: 100%;
    min-width: auto;
  }
  .nin_ja_social-box {
    padding-bottom: 0;
  }
  .nin_ja_player-title_heading {
    margin-right: 0;
  }
  .nin_ja_results-info__card_download {
    width: 40px;
    height: 40px;
  }
  .title {
    font-size: 12px;
  }
  .nain_ja_input-content {
    font-size: 14px;
  }
  .main_ja__card_time {
    font-size: 12px;
  }
  .main_ja__card_download {
    right: 2px;
  }
  .main_ja__card_download svg {
    width: 30px;
  }
  span.nin_ja_shareblock{
    left:8%
  }

}


@media screen and (max-width: 510px) {
  .nin_ja_track-info {
    margin-right: 0;
  }
  .nin_ja_player-title {
    margin-top: 0;
  }
  .nin_ja_copyright_box-mail {
    font-size: 22px;
    margin-top: 10px;
  }
  .nin_ja_player-title_heading {
    text-align: center;
    margin-top: 10px;
  }
  .progress-bar__container {
    flex-direction: column;
  }
  .ninja__blueSocial_vk {
    display: none;
  }
  .res-card_st-bf_results-info__card-subtext, .nin_ja_subinfo__time {
    display: none;
  }
  .nin_ja_player-title {
    text-align: center;
    margin-left: 0;
    justify-content: center;
    margin-bottom: 0;
  }
  .lnin_ja_description {
    margin-bottom: 20px;
  }
  .track {
    display: flex;
    flex-direction: column;
  }
  .nin_ja_mute-box {
    display: none;
  }
  .nin_ja_track-info {
    flex-direction: column;
    align-items: center;
  }
  .nin_ja_track-central {
    margin-bottom: 10px;
  }
  .nin_ja_copyright_box-mail{
    font-size: 20px;
  }
  span.nin_ja_shareblock{
    width: 96%;
    left: 0%;
  }
  .nin_ja_track-info, .nin_ja_track-central {
    height: 15px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

}

@media screen and (max-width: 470px) {
  .nin_ja_track-central_left-btn svg, .nin_ja_track-central_right-btn svg{
    width: 27px;
    height: 27px;
  }
  .play::before, .pause::before{
    width: 25px;
    height: 25px;
  }
  .nin_ja_description_content {
    display: none;
  }
  .ninja__bluePopular__box {
    display: none;
  }
  .nin_ja_player-title_heading {
    font-size: 14px;
    margin-top: -10px;
  }
  .nin_ja_track-info, .nin_ja_track-central {
    height: 15px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 425px) {
  .ninja__blueContainer{
    padding: 0 5px;
  }
  .main_ja__card-subtext{
    display: none;
  }
  .ninja__blueSidebar{
    margin-top: 70px;
  }
  .ninja_header_logo-gif{
    width: 80px;
  }
  .nin_ja_description_title {
    display: none;
  }
  .main_ja_card_artist, .main_ja_card_tracklink {
    max-height: 17px;
  }
  .header-nin_header_menu {
    flex-direction: column;
  }
  .header-nin_header_menu a {
    font-size: 24px;
  }
  .main_ja_card_download svg {
    width: 40px;
    height: 25px;
  }
  .main_ja_card_download {
    width: 40px;
    height: 20px;
  }

  .main_ja_card {
    height: 70px;
    padding: 10px 5px 5px 5px;
  }
  .main_ja_card_time {
    display: none;
  }
  .main_ja_card .start {
    transform: scale(0.6);
  }
  .main_ja_card_download {
    position: static;
  }
  .main_ja_card-subtext {
    display: none;
  }
  .res-card_st-bf_results-info__card {
    padding: 15px 10px;
  }
  .nin_ja_popular__box {
    justify-content: center;
  }
  .nin_ja_melodious__heading {
    text-align: center;
  }
  .nin_ja_description_title {
    text-align: center;
  }
  .nin_ja_subinfo__time {
    display: none;
  }
  .nin_ja_results-info__card_time {
    margin-right: 0;
  }
  .nin_ja_results-info__card-subtext {
    margin-right: 10px;
    margin-left: 15px;
  }
  .nin_ja_results-info__card_artist, .nin_ja_results-info__card_space, .nin_ja_results-info__card_tracklink {
    font-size: 14px;
  }
  .nin_ja_results-info__card_like {
    display: none;
  }

  .nin_ja_results-info__card_download svg {
    width: 30px;
    height: 30px;
  }
  //.nin_ja_results-info__card.pausing a.nin_ja_results-info__card_link::before {
  //  content: url(../images/musicTheme-play.svg);
  //  padding-left: 12px;
  //  padding-right: 8px;
  //  height: 22px;
  //}
  //.nin_ja_results-info__card_link::before {
  //  width: 20px;
  //  height: 20px;
  //  padding: 11px;
  //}
  .nin_ja_results-info__card .start {
    width: 40px;
    height: 40px;
  }
  .nin_ja_vk::before {
    min-width: calc(300px + 6px);
  }
  .nin_ja_melodious__list {
    display: flex;
    flex-direction: column;
  }
  .nin_ja_melodious__list a {
    font-size: 30px;
  }
  .header-nin_header_logo-link {
    font-size: 30px;
  }

  .nin_ja_copyright_box-mail {
    font-size: 18px;
  }
  nin_ja_letter__title {
    font-size: 18px;
  }
  .nin_ja_letter__link {
    font-size: 14px;
  }
  .nin_ja_story_heading {
    font-size: 18px;
  }
  .nin_ja_story_track-link {
    font-size: 12px;
  }
  .header-nin_header_logo-link {
    font-size: 22px;
  }
  .nin_ja_genre-item_link {
    font-size: 12px;
  }
  .header-nin_header_menu a {
    font-size: 16px;
  }
  span.nin_ja_shareblock {
    width: 94%;
    left: 2%;
  }
  span.nin_ja_shareblock h1 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
  span.nin_ja_shareblock a {
    font-size: 18px;
  }
  .header-nin_header_menu a {
    margin-left: 0px;
  }

}

@media screen and (max-width: 375px) {
  span.nin_ja_shareblock {
    width: 92%;
    top: 43%;
    left: 0%;
  }


} 