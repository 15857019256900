@import "./vars";


@mixin scrollbars($size, $foreground-color, $foreground-color-hover, $background-color: mix($foreground-color, white,  50%), $radius: 0) {
}

/*Border Component END*/

ul {
  padding: 0;
}



html,
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  background: var(--clr-2);
  background-image: url(https://svgsilh.com/svg/297476.svg);
  background-size: 7%;
  background-attachment: fixed;
}



.nin_ja_letter__link {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 16px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;

  &:hover {
    background: var(--clr-2);
  }
}

.ninja__blueSocial {
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.header-nin_header_logo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.ninja__blueSite {
  display: flex;
  padding: 20px;
  background: var(--clr-3);
  border-radius: 9px;
  column-gap: 40px;
}

.ninja__blueSidebar {

}

.nain_ja_input {
  width: 100%;

  &-content {
    width: 100%;
    height: 100%;
    background: none;
    font-size: 15px;
    color: var(--clr-3);
    font-weight: 700;
    border: none;

    &:hover, &:focus, &:active {
      border: none;
      outline: 0;
      outline-offset: 0;
    }

    &::placeholder {
      font-weight: 700;
      color: var(--clr-3);
    }
  }
}


.nin_ja_letter {
  justify-content: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &__link {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    color: var(--clr-4);
    font-size: 16px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    border-radius: 10px;

    &:hover {
      background: var(--clr-2);
      color: var(--clr-3)
    }
  }

  &__heading {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 5px;

  }

  &__title {
    margin-bottom: 5px;
    position: relative;
    margin: 0;
    display: flex;
    color: var(--clr-1);
    font-size: 20px;

    &::after {
      content: "";
      height: 2px;
      width: 120px;
      background: var(--clr-2);
      bottom: -5px;
      left: 90px;
      position: absolute;
      z-index: 1;
    }
  }

  &__logo {
    margin-right: 20px;
    fill: none;
    width: 40px;
    height: 40px;
    stroke: var(--clr-1);
  }
}

.header-nin_mobile__button {
  display: none;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  z-index: 9999;
  flex-direction: column;

  &-line {
    margin-bottom: 5px;
    margin-top: 5px;
    display: block;
    position: relative;
    width: 40px;
    height: 4px;
    border-radius: 10px;
    background-color: var(--clr-3);
  }
}

.ninja__blueContainer {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
}

.nin_ja_sotial-network-vk {
  display: flex;
  flex-direction: column-reverse;
}

.header-nin_header {
  margin-bottom: 40px;

  &_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_menu a {
    margin-left: 25px;
  }

  &_menu a {
    color: var(--clr-4);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    background: var(--clr-3);
    width: 100%;
    box-sizing: border-box;
    margin: 5px 15px;
    padding: 15px;

    &:hover {
      color: var(--clr-1);
    }

  }

  &_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_logo-link {
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: 900;
    color: var(--clr-3);
  }
}

.ninja__blueWrapper {
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  width: calc(100% - 340px)
}
.header-nin_header_logo_1{
  display: none;
}

.ninja__bluePopular {
  padding: 0 15px;
  flex-direction: column;
  display: flex;

  &__box {
    flex-wrap: wrap;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      position: absolute;
      width: 100%;
    }
  }

  &_title {
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 700;
    color: var(--clr-4);
    margin: 0;
    font-size: 25px;
    z-index: 2;

    &::after {
      content: "";
      content: "";
      height: 4px;
      width: 150px;
      background: var(--clr-2);
      bottom: -5px;
      left: 40px;
      position: absolute;
      z-index: 1;
    }
  }
}


.pages {
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px;
  font-weight: 700;
  column-gap: 5px;
  font-size: 15px;

  & a {
    color: #fff;

    &:hover {
      color: var(--clr-2);
    }
  }
}

.nin_ja_result-of-search:hover {
  .nin_ja_result-of-search_link {
    color: var(--clr-2);
  }


}

.music-artist {
  color: var(--clr-1);
}

.active {
  display: flex !important;
}

.nin_ja_result-of-search {
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background-color: var(--clr-2);
  }

  &_link {
    font-size: 18px;
    color: var(--clr-1);
    margin-left: 15px;
  }

  &_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.main_ja__card_artists{
  max-height: 30px;
}

.main_ja {
  display: flex;
  flex-direction: column;

  p {
    font-size: 28px;
    text-align: center;
    color: #fff;
  }

  &__card_artist, &__card_tracklink {
    overflow: hidden;
    max-height: 30px;
  }

  &__card {
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: all .4s ease;
    padding: 10px;
    border: 2px solid var(--clr-2);
    margin-bottom: 15px;
    border-radius: 10px;

    &_artists {
      text-align: left;
      width: 100%;
      overflow: hidden;
      color: var(--clr-1);

      .main_ja__card_artist {
        width: auto;
      }
    }

    &_artist {
      &:hover {
        color: var(--clr-14);
      }
    }
  }


  &__card.plays a.main_ja__card_link::before {
    content: url('../images/card-pause.svg');
    height: 30px;
    width: 30px;
    //content:""
    //background-image:url('../images/card-pause.svg');
    //background-position: inherit;
    //background-repeat: no-repeat;
    //padding: 15px;
    //width: 1px
  }

  &__card_like {
    height: 30px;
  }

  &__card .start {
    cursor: pointer;
  }

  &__card-subtext {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 5px;
    justify-content: flex-end;
    margin-right: 10px;
  }

  &__card_name {
    height: 100%;
    line-height: normal;
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  &__card_time {
    font-size: 14px;
    color: var(--clr-1);
  }

  &__card_share {
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;
  }

  &__card_artist, &__card_tracklink {
    color: var(--clr-1);
    width: 100%;
  }

  &__card_download {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    right: 5px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &__card_link {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-right: 10px;
    color: var(--white-color);

    &::before {
      content: url('../images/card-play.svg');
      width: 30px;
      height: 30px;
    }
  }
}

.main_ja__card_like {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  & svg {
    width: 30px;
    height: 30px;
  }
}

.nin_ja_music {
  &__title {
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title-heading {
    margin: 0;
    color: var(--clr-3);
    font-weight: 900;
    font-size: 20px;
  }

  &__title-logo {
    width: 50px;
    height: 50px;
    fill: var(--clr-3);
    margin-right: 5px;
  }
}

.submit {
  cursor: pointer;
  transition: all .2s ease;
  border: none;
  background: 0 0;
  height: 100%;
  padding-left: 15px;
  display: flex;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
    transition: all 0.8s;
    fill: var(--clr-1);
  }
}

.dur {
  margin-right: 0;
  position: relative;
  line-height: normal;
}

.title {
  flex-direction: column;
  display: flex;
  align-items: center;
  font-weight: 400;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-around;
  font-size: 14px;
  margin-right: 15px;
  height: 100%;
  row-gap: 5px;
}

.nin_ja_logo-link {
  font-size: 35px;
  font-weight: 700;
  color: var(--clr-1);
}

.nin_ja_footer {
  background-color: var(--clr-3);
  padding: 15px;
  border-radius: 10px;
  margin-top: 30px;

  &_wrapper {
    font-size: 20px;
    position: relative;
    color: var(--clr-1);
  }

  &_copy {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nin_ja_player-link {
  font-size: 30px;
  color: #fff;
}

.nin_ja_story_track-title {
  width: 100%;
  word-wrap: break-word;
}

.nin_ja_story_heading {
  position: relative;

  &::after {
    content: "";
    height: 2px;
    width: 120px;
    background: var(--clr-2);
    bottom: -5px;
    left: 90px;
    position: absolute;
    z-index: 1;
  }
}

.nin_ja_story {
  &_heading {

    display: flex;
    justify-content: center;
    margin: 0;
    font-weight: 700;
    color: var(--clr-1);
    font-size: 20px;
    margin-bottom: 20px;
  }

  &_track {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &_track-link {
    color: var(--clr-14);
    margin-bottom: 10px;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    margin-right: 10px;
    background-color: var(--clr-5);
    padding: 5px;
    border-radius: 10px;

    &:before {
      //content: "";
      //width: 0;
      //height: 15px;
      //background: #fff;
      //padding-right: 15px;
      //margin-top: 8px;
      //margin-right: 20px;
    }

    &:hover {
      .nin_ja_story_track-title {
        color: var(--clr-1);
      }

      &::before {
        background: var(--clr-1);
      }
    }
  }

  &__track-img {
    height: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);

    i {
      font-size: 30px;
    }
  }

  &__track-title {
    font-size: 20px;
    font-weight: 700;
  }
}

.nin_ja_description {
  width: 450px;
  font-size: 22px;

  &_content {
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  &_icon {
    font-size: 25px;
    margin-right: 10px;
  }

  &_title {
    display: flex;
    text-align: center;
  }
}

.nin_ja_boxline {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.nin_ja_copyright_box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 200px;
  margin-top: 20px;

  &-logo {
    padding: 0 10px;
  }

  &-mail {
    color: var(--clr-1);
    display: flex;
    align-items: center;

    a {
      color: var(--clr-2);
      margin-left: 10px;
      font-weight: 700;
      font-size: 17px;
    }
  }

  &-link {
    color: var(--clr-14);
  }
}

.nin_ja_social-box--btnBox {
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
}

.about {
  &__inner {
    margin-bottom: 20px;
    padding: 5px;
    color: var(--clr-2);

  }
}

.boxline {
  margin: 10px 0;

  &__top p {
    line-height: 1.5;
    font-size: 20px;
    color: #fff;
  }

  &__bottom {
    font-size: 20px;
    font-weight: 700;
    color: var(--white-color);
    text-align: center;
  }
}

.nin_ja_sotial-network-vk {
  &_btn {
    border-radius: 20px;
    height: 60px;
    border: 1px solid var(--clr-1);
    color: var(--clr-3);
    background-color: var(--clr-11);
    width: 100%;
    font-size: 20px;
    text-align: center;
    font-weight: 700;

    &::placeholder {
      color: var(--clr-1);
    }
  }

  &_link {
    border-radius: 20px;
    font-size: 18px;
    font-weight: 700;
    border: 1px solid var(--clr-1);
    color: var(--clr-1);
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: color 150ms ease-in-out;
    transition: color 150ms ease-in-out;
    height: 60px;
    display: flex;
    width: 100%;
    margin-top: 15px;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: var(--clr-11);
      z-index: -1;
      -webkit-transition: width 150ms ease-in-out;
      transition: width 150ms ease-in-out;
    }

    &:hover {
      color: var(--clr-14);
    }

    &:hover::after {
      width: 110%;
    }
  }

  &_input {
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  &_content {
    line-height: 2.2;
    font-size: 19px;
    color: var(--clr-14);
    //background: var(--clr-2);
    padding: 20px;
    font-weight: 700;
    border-radius: 25px;
  }
}

.active__selection::marker, .active__genre::marker {
  color: var(--clr-1);
}

.active__selection, .active__genre {
  font-weight: 700;
  color: var(--clr-1);
  font-size: 18px;
  margin-bottom: 5px;
}

#genre {
  padding: 25px 0 0;
  list-style-type: none;
  position: relative;
}

.nin_ja_genre {
  &-list {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: flex;
    margin-bottom: 20px;


    &::before {
      //content: ">";
      //color: var(--clr-4);
      //font-size: 20px;
      //margin-right: 10px
    }

    &_link {
      font-weight: 700;
      font-size: 14px;
      color: var(--clr-3);
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }

    &:hover {
      .nin_ja_genre-item_link {
        color: var(--clr-1);
      }

      &::before {
        color: var(--clr-1);
      }
    }
  }

  &-item_logo {
    margin-right: 15px;
    font-size: 25px;
    color: var(--clr-14);
  }
}

.ninja_header_logo-gif {
  width: 120px;
}

.active__selection {
  list-style: none;
}

#trackAjax {
  z-index: 1;
  padding: 0;
}

#nain_ja_search__form {
  padding: 10px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  position: relative;
  height: auto;
  background: var(--clr-2);
  margin-bottom: 25px;
  border-radius: 9px;
}


.pleer {
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0 auto;
  padding-bottom: 10px;
  background: var(--clr-3);
  transition: all .6s ease;
}


.nin_ja_volume {
  margin-left: 6px;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 14px;
  margin-top: 0;
  border-radius: 10px;
  z-index: 202;
  border: 3px solid var(--clr-2);
  position: relative;

  .nin_ja_volume__line {
    cursor: pointer;
    height: 14px;
    width: 100%;
    float: left;
    -webkit-transition: width .7s ease;
    transition: width .7s ease;
    background: var(--clr-2);
    border-radius: 10px;
    box-sizing: border-box;
  }
}


.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-6);
}

a.play {
  color: var(--white-color);
  //border: none;

  &:hover {
    text-decoration: none;
  }
}


.play, .pause {

  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 3px solid var(--clr-2);
  padding: 10px;
  border-radius: 90px;
  border-radius: 60px;
}

.play {
  &::before {
    content: url("../images/card-play.svg");
    width: 35px;
    height: 35px;
  }
}

.pause {
  &::before {
    content: url('../images/card-pause.svg');
    width: 35px;
    height: 35px;
  }
}

.nin_ja_track-central_left-btn,
.nin_ja_track-central_right-btn {
  svg {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    fill: var(--clr-7);
  }
}

.nin_ja_track-central_right-btn svg {
  transform: rotate(180deg);
}

.uil {
  cursor: pointer;
}

.nin_ja_track-central {
  align-items: center;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin-right: 30px;
}

.progressBarContainer-playBar {
  position: relative;
  display: inline-block;
  height: 11px;
  background: var(--clr-2);
  color: var(--clr-2);
}

.progressBarContainer--downloadBar {
  display: block;
  position: absolute;
  height: 70px;
  width: 0;
}

.download-bar {
  display: block;
  height: 70px;
  width: 100px;
  z-index: 201;
  border-radius: 20px;
}

.progress-bar__container-playbar {
  position: absolute;
  left: 0;
  display: inline-block;
  height: 17px;
  background: #3a425d;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--white-color);
    border: 2px solid #061161;
    display: none;
  }
}

.track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0;
  margin-top: 10px;
}

.track__track-timebox {
  position: absolute;
  width: 100%;
  height: 17px;
  background: var(--clr-2);
  border-top: 4px solid var(--clr-3);
}

.progress-bar__container {
  cursor: pointer;
  transition: height linear 0.3s;
  height: 17px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.nin_ja_zero-time {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.nin_ja_timeBox {
  font-weight: 700;
  margin-right: 20px;
  font-size: 16px;
  color: #fff;
}

.nin_ja_player-title_heading {
  z-index: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-items: center;
  font-weight: 700;
  flex-wrap: wrap;
  overflow: hidden;
  font-size: 17px;
  margin-right: 20px;
  margin-left: 0;
  color: var(--clr-2);
}

.nin_ja_mute-box {
  width: 200px;
  justify-content: flex-end;
  display: flex;
  height: 100%;

  .SocialBox {
    min-height: 36px;
    justify-content: flex-start;

    .SocialBox--btnBox {
      border-color: var(--white-color);
    }
  }

  &__muteUnmute {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.loaded {
  display: none;
}


.nin_ja_time {
  &_space {
    font-weight: 700;
    margin: 0 5px;
  }

  display: flex;
  color: #fff;
  position: absolute;
  left: 5px;
  z-index: 2;
  top: 0;
  bottom: 0;
  align-items: center;
}

.nin_ja_track__leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .player__Collapse {
    display: none;
  }
}

.nin_ja_playerTime__box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.nin_ja_track-info {
  align-items: center;
  display: flex;
}

.nin_ja_player-title {
  width: 100%;
  justify-content: center;
  margin: 0 20px;
  display: flex;
  align-items: center;
}

.nin_ja_player-title_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  svg {
    width: 40px;
    height: 30px;
    fill: var(--clr-14);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  padding: 2px 5px 2px 0;
  width: 40px;
  height: 24px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
}

.nin_ja_mute,
.nin_ja_unmute {
  svg {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 30px;
  }
}

.nin_ja_mute {
  fill: var(--clr-7);
}

.nin_ja_unmute {
  fill: none;
}


#GeneralBox,
#toTop {
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--clr-3);
    }

    &:hover {
      background-color: var(--clr-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--clr-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}


/*SHAREBOX*/
span {
  &.nin_ja_shareblock {
    border-radius: 15px;
    z-index: 10000;
    width: 500px;
    display: block;
    background-color: var(--clr-11);
    position: fixed;
    top: 50%;
    left: 20%;
    margin: -100px 0 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    border: 3px solid var(--clr-2);
    background: var(--clr-3);

    b {
      border: 4px solid var(--clr-1);
      position: absolute;
      top: -20px;
      right: -20px;
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      margin-bottom: -24px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 30px;
      line-height: 40px;
      font-size: 25px;
    }

    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--clr-11);
      font-family: sans-serif;
      font-size: 25px;
      font-weight: 400;
      display: block;
      color: var(--clr-1);
      border-bottom: 4px solid var(--white-color);
    }

    span {
      //width: 400px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    }

    a {
      display: flex;
      align-items: center;
      color: var(--clr-1);
      font-size: 25px;
      //background: url(../images/share.png) 10px 0 no-repeat;
      margin: 10px 10px 10px 0;
      padding: 0 10px 0 25px;

      &.vk::before {
        display: inline-block;
        content:"";
        background-image:url("../images/vkontakte.png") ;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 35px;
        height: 35px;
        margin-right: 15px;
      }

      &.telegram::before {
        display: inline-block;
        content:""; 
        background-image:url("../images/telegram.png") ;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 35px;
        height: 35px;
        margin-right: 15px;

      }

      &.whatsapp::before {
        display: inline-block;
        content:"";
        background-image:url("../images/whatsapp.png") ;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 35px;
        height: 35px;
        margin-right: 15px;
      }

      &.mm {
        background-position: 0 -116px;
      }

      &.gp {
        background-position: 0 -146px;
      }
    }
  }
}


.ninja__blueSocial_vk {
  height: 50px;
  width: 200px;
  background-color: var(--clr-3);
  border-radius: 30px;
}


/* 
========================
      BUTTON ONE
========================
*/
.ninja__blueDownload_vk {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 200px;
  cursor: pointer;
  color: #FFF;
  transition: all 0.3s;
  position: relative;
  border-radius: 30px;
}

.ninja__blueDownload_vk span {
  transition: all 0.3s;
  font-weight: 700;
  font-size: 18px;
}

.ninja__blueDownload_vk::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  transform: scale(0.1, 1);
}

.ninja__blueDownload_vk:hover span {
  letter-spacing: 2px;
}

.ninja__blueDownload_vk:hover::before {
  opacity: 1;
}

.ninja__blueDownload_vk::after {
  border-radius: 30px;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}

.ninja__blueDownload_vk:hover::after {
  opacity: 0;
}

.nin_ja_unmute svg {
  height: 50px;
}

.nin_ja_active__selection {
  &-content {
    display: flex;

    &:hover {
      & a {
        color: var(--clr-1) !important;
      }

      .nin_ja_active__selection-like {
        fill: var(--clr-1);
      }
    }

    a {
      color: var(--clr-14);
    }
  }

  &-like {
    fill: var(--clr-14);
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
}

.ninja__blueSidebar {
  flex-direction: column;
  display: flex;
  width: 320px;
  background: var(--clr-2);
  border-radius: 9px;
  padding: 15px;

  &_list {
    flex-direction: column;
    width: 300px;
    display: flex;
    margin-left: 40px;
  }

  & ul {
    font-weight: 700;
    margin-bottom: 0;
  }

  .nin_ja_sidebar-header {
    border-radius: 15px;
    padding: 20px 0 10px;
    background: var(--clr-10);

    &_logo {
      a {
        color: var(--white-color);
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  ul.components {
    padding: 20px 0;
  }

  ul {
    p {
      color: var(--white-color);
      padding: 10px;
      font-size: 18px;
    }

  }

  a[aria-expanded="true"] {
    position: relative;
    font-size: 35px;
  }

  a[data-toggle="collapse"] {
    position: relative;
  }

  a.article {
    color: var(--white-color) !important;

    &:hover {
      background: var(--clr-10) !important;
      color: var(--white-color) !important;
    }
  }

}


