:root{
    --clr-1: #fff;
    --clr-2: #fede00;
    --clr-3: #181818;
    --clr-4: #fff;
    --clr-5: #ff5b46;
    --clr-6: rgba(255,255,255,.1);
    --clr-7: #3a425d;


    --clr-8: #fac87c;
    --clr-10: var(--clr-background);
    --background: #edeef0;
    
    --clr-14: #fff;
    --white-color: #fff;
}